import React from 'react';
import ReactDOM from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ApplicationErrorBoundary } from '@idbs/idbs-react-components';
import '@idbs/idbs-analytics-app/idbs-analytics-app.js';
import '@idbs/idbs-themed-app/idbs-themed-app.js';
import Communication from './ewb-communication/communication';
import { AppShell } from './AppShell';
import './index.css';
import '@idbs/idbs-themes/styles/eln/style.css';
import translations from './translations';
import ElnSapi from './services/elnSapi';

const clientLocale = navigator.language || 'en_GB';
const language = clientLocale.split(/[-_]/)[0];

/*
 * See https://github.com/IDBusinessSolutions/idbs-analytics-app for instructions on how to
 * setup google analytics for your application.
 * Also see https://idbs-hub.atlassian.net/wiki/spaces/WEBDEV/pages/3520823396/Google+Tag+Manager+Application+Analytics
 * for further instructions and information that will be helpful
 */
const analyticsId = 'GTM-T2R46ZN';

// Wait for our mock service worker to start before doing anything else, note this
// only has any effect in 'dev' mode
const prepare = async () => {
  if (process.env.NODE_ENV === 'development') {
    const { worker } = await import('./mocks/browser');
    return worker.start();
  }

  return Promise.resolve();
};

prepare().then(() => {
  Communication.initialise((option) => {
    const { mode, configServiceUrl, sapiUrl } = option;
    const queryClient = new QueryClient();
    ElnSapi.initialise(sapiUrl);

    /*
     * Calls to the config service need a pasToken, the web components that make these
     * requests expect this to be stored in sessionStorage under an 'access_token' key
     * so ask our container for a PAS token that can be used
     */
    Communication.getValidPasToken((pasToken) => {
      sessionStorage.setItem('access_token', `"${pasToken}"`);
      ReactDOM.createRoot(document.getElementById('root')).render(
        <React.StrictMode>
          <idbs-analytics-app analyticsid={analyticsId} configurl={configServiceUrl} />
          <idbs-themed-app configurl={configServiceUrl}>
            <ApplicationErrorBoundary>
              <IntlProvider locale={clientLocale} messages={translations[language] || translations.en}>
                <QueryClientProvider client={queryClient}>
                  <AppShell mode={mode} closeApplication={Communication.shutdown} />
                </QueryClientProvider>
              </IntlProvider>
            </ApplicationErrorBoundary>
            {/*
             * Set an empty 'placeholder' which is used by <idbs-themed-app> to ensures that the UI isn't
             * visible until AFTER the the 'theme' information has been retrieved from the config service.
             * This prevents a 'style change flash' for slow queries where the initial default ELN styling
             * is visible for a small time before changing to Polar styling (if configured)
             */}
            <div slot='placeholder' />
          </idbs-themed-app>
        </React.StrictMode>,
      );
    });
  });
});
