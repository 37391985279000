import styled from 'styled-components';

export const CachedItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const CachedItemsList = styled.div`
  max-height: 100px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const CachedItem = styled.span`
  margin-left: 10px;
`;
