export const IDBS_EWORKBOOK_DOMAIN = 'idbs-eworkbook.com';
export const EWORKBOOKCLOUD_DOMAIN = 'eworkbookcloud.com';
export const IDBS_CLOUD_DOMAIN = 'idbs-cloud.com';
export const IDBS_DEV_DOMAIN = 'idbs-dev.com';
export const IDBS_STAGING_DOMAIN = 'idbs-staging.com';
export const IDBS_DOMAINS = [
  IDBS_EWORKBOOK_DOMAIN,
  EWORKBOOKCLOUD_DOMAIN,
  IDBS_CLOUD_DOMAIN,
  IDBS_DEV_DOMAIN,
  IDBS_STAGING_DOMAIN,
];

/**
 * Returns true if supported IDBS domain
 * @param toCheck The domain to check
 * @returns {boolean}
 */
export const isIDBSDomain = (toCheck) => {
  return IDBS_DOMAINS.some((domain) => toCheck.indexOf(`.${domain}`) > 0);
};
/**
 * Returns true if we are running in anything but production mode.
 */
export const isDevMode = () => process.env.NODE_ENV !== 'production';

/**
 * Returns true if the application is running inside an iFrame.
 */
export const isSubFrame = () => window.location !== window.parent.location;

/**
 * Returns true if the event can be trusted, i.e., it's origin is OK, false otherwise.
 * @param {*} event  The event.
 */
export const trustMessage = (event) =>
  isDevMode()
    ? event.origin.startsWith(`${window.location.protocol}//${window.location.hostname}`)
    : event.origin.startsWith('https://') && isIDBSDomain(event.origin);
