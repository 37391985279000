import styled from 'styled-components';

export const Main = styled.div`
  display: flex;
  flex-direction: row;

  > svg {
    color: var(--theme-main-700);
    width: 30px;
    height: 30px;
  }
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left: 15px;
  margin-right: 15px;
  width: 100%;

  .title,
  .description {
    color: var(--theme-main-700);
  }

  .title {
    font-size: 15px;
    font-weight: 500;
  }

  .description {
    font-size: 14px;
  }
`;
