import React from 'react';
import { Button, ModalDialog } from '@idbs/idbs-react-components';
import { useIntlMessage } from '../utils/useIntlMessage';
import DialogInfo from './DialogInfo';
import { CachedItemsContainer, CachedItemsList, CachedItem } from './CachedItemsDialog.style';

const CachedItems = ({ items }) => {
  const intlMessage = useIntlMessage();

  return (
    <CachedItemsContainer>
      <span>{intlMessage('app.CachedItemsDialog.cachedData')}</span>
      <CachedItemsList>
        {items.map((item) => (
          <CachedItem key={item}>{item}</CachedItem>
        ))}
      </CachedItemsList>
    </CachedItemsContainer>
  );
};

const CachedItemsDialog = ({ show, onHide, selectedEntity, onUnlockEntity, cachedItems }) => {
  const intlMessage = useIntlMessage();

  return (
    <ModalDialog visible={show} onHide={onHide} testId='cached-items-dialog'>
      <ModalDialog.Header>{intlMessage('app.CachedItemsDialog.cachedItemsExist')}</ModalDialog.Header>
      <ModalDialog.Body>
        <DialogInfo
          title={intlMessage('app.CachedItemsDialog.title', { name: selectedEntity.entityName })}
          description={intlMessage('app.CachedItemsDialog.description')}
          info={intlMessage('app.CachedItemsDialog.info')}
        >
          <CachedItems items={cachedItems} />
        </DialogInfo>
      </ModalDialog.Body>
      <ModalDialog.Footer>
        <Button
          large
          emphasis='secondary'
          style={{ marginRight: '10px' }}
          onClick={onHide}
          testId='cancel-cache-dialog'
        >
          {intlMessage('app.cancel')}
        </Button>
        <Button
          large
          emphasis='primary'
          style={{ marginRight: '10px' }}
          onClick={() => onUnlockEntity({ retainCache: true })}
          testId='retain-cache'
        >
          {intlMessage('app.CachedItemsDialog.retainCache')}
        </Button>
        <Button large emphasis='primary' onClick={() => onUnlockEntity({ clearCache: true })} testId='clear-cache'>
          {intlMessage('app.CachedItemsDialog.clearCache')}
        </Button>
      </ModalDialog.Footer>
    </ModalDialog>
  );
};

export default CachedItemsDialog;
