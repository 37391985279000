import styled from 'styled-components';
import { Dots } from '@idbs/idbs-react-components';

export const Background = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--color-neutral-600);
  opacity: 0.8;
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Shell = styled.div`
  height: 80%;
  max-height: 500px;
  width: 80%;
  max-width: ${({ mode }) => (mode === 'administrator' ? '1220px' : '820px')};
  display: flex;
  flex-direction: column;
  background-color: var(--color-neutral-white);
  box-shadow: 0px 0px 10px 0px var(--color-neutral-600);
`;

export const HeaderBar = styled.div`
  position: relative;
  height: 40px;
  color: var(--color-neutral-white);
  background-color: var(--theme-main-700);
  display: flex;
  align-items: center;
  padding: 0px 10px 0px 15px;

  > svg {
    position: absolute;
    right: 15px;
    height: 15px;
    width: 15px;
    cursor: pointer;

    &:hover {
      color: var(--theme-main-400);
    }
  }
`;

export const ButtonPanel = styled.div`
  width: 100%;
  vertical-align: middle;
`;

export const ApplicationContent = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;

  .summary-table {
    // Override the styling of idbs-react-advanced-table so that it fits our requirements
    width: 100%;

    .table {
      min-width: unset !important; // Need this to stop the table setting its own 'wrong' min-width
    }
  }
`;

export const LoadingIndicator = styled(Dots)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
