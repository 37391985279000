import { isDevMode, trustMessage } from '../admin-app-sdk/utils';
import { sendLoadedMessage, sendGetValidPasTokenMessage, sendShutdownMessage } from './messages';

/**
 * This file contains all the code necessary for handling communications between the application and
 * its container (the web client). This communication is achieved using https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage
 */

/**
 * If the app is running in development mode then we need to perform some additional setup
 * to mimic the container environment
 */
const developmentSetup = () => {
  if (isDevMode()) {
    const searchParams = new URLSearchParams(window.location.search.substring(1));
    const mode = searchParams.has('mode') ? searchParams.get('mode') : 'user';

    window.postMessage(
      JSON.stringify({
        type: 'initialise',
        data: {
          configServiceUrl: `${window.location.origin}/configservice`,
          sapiUrl: `${window.location.origin}/eln-sapi-mock/`,
          mode,
        },
      }),
    );
  }
};

/**
 * Passes the given pasToken to any callback functions that were
 * waiting for it
 */
const sendPasToken = (pasToken) => {
  while (awaitingPasTokenCallbacks.length > 0) {
    const callback = awaitingPasTokenCallbacks.shift();
    try {
      callback(pasToken);
    } catch (e) {
      // Ignore any errors thrown by the callback as we need
      // to let any other callbacks run
    }
  }
};

const awaitingPasTokenCallbacks = [];
let initialised;

export default class Communication {
  /**
   * Call this to initialise the application and start communication with the container
   * @param {*} startApplication this will be called when a message is received from the container, it will be passed
   * a mode, the config service url and the SAPI url
   */
  static initialise(startApplication) {
    if (initialised) {
      return;
    }
    initialised = true;

    // Send a message to let our container know that the application is ready.
    // It (EWB Web Client) will respond by sending an 'initialise' message containing
    // the information necessary for this application to function correctly
    sendLoadedMessage();

    window.addEventListener('message', (event) => {
      if (!trustMessage(event)) {
        console.error(`Received untrusted message from ${event.origin}`);
        return;
      }

      if (event.data) {
        const { type, data } = typeof event.data === 'string' ? JSON.parse(event.data) : event.data;

        if (type === 'initialise') {
          startApplication({
            mode: data.mode,
            configServiceUrl: data.configServiceUrl,
            sapiUrl: data.sapiUrl,
          });
        } else if (type === 'authenticated') {
          sendPasToken(data.pasToken);
        }
      }
    });

    developmentSetup();
  }

  /**
   * Call this when the application wishes to shutdown
   */
  static shutdown() {
    sendShutdownMessage();
  }

  /**
   * Call this whenever the application needs a PAS token to make a REST call to the ELN SAPI,
   * it will ensure that a valid PAS token is always used
   * @param {*} callback called with a single parameter containing the PAS token
   */
  static getValidPasToken(callback) {
    if (isDevMode()) {
      // When running in dev mode we don't use the message passing for PAS token request
      // as this doesn't work when running under cypress
      awaitingPasTokenCallbacks.push(callback);
      sendPasToken('dummy pas token');
      return;
    }

    if (awaitingPasTokenCallbacks.length === 0) {
      // Only request a PAS token if we haven't already got a request in flight
      sendGetValidPasTokenMessage();
    }
    awaitingPasTokenCallbacks.push(callback);
  }
}
