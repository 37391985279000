import React from 'react';
import { Button, ModalDialog } from '@idbs/idbs-react-components';
import { useIntlMessage } from '../utils/useIntlMessage';
import DialogInfo from './DialogInfo';

const EntityUnlockDialog = ({ isAdministrator, show, onHide, selectedEntity, onUnlockEntity }) => {
  const intlMessage = useIntlMessage();

  return (
    <ModalDialog visible={show} onHide={onHide} testId='unlock-entity-dialog'>
      <ModalDialog.Header>{intlMessage('app.EntityUnlockDialog.unlockSelectedEntity')}</ModalDialog.Header>
      <ModalDialog.Body>
        <DialogInfo
          title={intlMessage(isAdministrator ? 'app.EntityUnlockDialog.title' : 'app.EntityUnlockDialog.title.user', {
            name: selectedEntity.entityName,
            fullName: selectedEntity.lockedBy,
          })}
          description={intlMessage('app.EntityUnlockDialog.description')}
          info={intlMessage(isAdministrator ? 'app.EntityUnlockDialog.info' : 'app.EntityUnlockDialog.info.user')}
        />
      </ModalDialog.Body>
      <ModalDialog.Footer>
        <Button
          large
          emphasis='secondary'
          style={{ marginRight: '10px' }}
          onClick={onHide}
          testId='cancel-unlock-dialog'
        >
          {intlMessage('app.cancel')}
        </Button>
        <Button large emphasis='primary' onClick={() => onUnlockEntity()} testId='unlock-entity'>
          {intlMessage('app.unlockEntity')}
        </Button>
      </ModalDialog.Footer>
    </ModalDialog>
  );
};

export default EntityUnlockDialog;
