import React from 'react';
import { InfoIcon } from '@idbs/idbs-react-icon';
import { Main, Details } from './DialogInfo.style';

const DialogInfo = ({ title, description, info, children }) => {
  return (
    <Main>
      <InfoIcon />
      <Details>
        <span className='title'>{title}</span>
        <span className='description'>{description}</span>
        <div className='information'>{info}</div>
        {children}
      </Details>
    </Main>
  );
};

export default DialogInfo;
