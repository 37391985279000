/**
 * Contains the messages that are sent to the ELN Web Client (the 'container') from this application
 */

// Makes sure the messages are only received by the parent of the IFRAME
const getTargetHost = (parentUrl = document.referrer) => {
  if (!parentUrl) {
    return;
  }
  const urlElements = parentUrl.split('/');
  return `${urlElements[0]}//${urlElements[2]}`;
};

// Sent when the application has loaded - this triggers the web client to send an 'initialisation'
// message containing the information this application needs to communicate with EWB
const sendLoadedMessage = () => {
  window.parent.postMessage(
    {
      type: 'admin:loaded',
    },
    getTargetHost(),
  );
};

// Sent when the user chooses to exit from this application. This causes the web client to remove
// the IFRAME containing this application
const sendShutdownMessage = () => {
  window.parent.postMessage(
    {
      type: 'admin:shutdown',
    },
    getTargetHost(),
  );
};

// Sent before this application makes a REST call to the EWB SAPI, this call results in the web client
// returning a valid PAS token to be used with the REST call
const sendGetValidPasTokenMessage = () => {
  window.parent.postMessage(
    {
      type: 'admin:get-valid-pas-token',
    },
    getTargetHost(),
  );
};

export { sendLoadedMessage, sendGetValidPasTokenMessage, sendShutdownMessage };
