import React, { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { ActionPanel, Button, ErrorPage } from '@idbs/idbs-react-components';
import { CrossIcon } from '@idbs/idbs-react-icon';
import { useIntlMessage } from './utils/useIntlMessage';
import {
  ButtonPanel,
  ApplicationContent,
  Background,
  Overlay,
  Shell,
  HeaderBar,
  LoadingIndicator,
} from './AppShell.style';
import EntityUnlockDialog from './components/EntityUnlockDialog';
import CachedItemsDialog from './components/CachedItemsDialog';
import EntitiesTable from './components/EntitiesTable';
import ElnSapi from './services/elnSapi';

const AppShell = ({ mode, closeApplication }) => {
  const intlMessage = useIntlMessage();
  const queryClient = useQueryClient();

  const { isLoading, isError } = useQuery(['locked-entities'], () => ElnSapi.getEntityLocks(mode), {
    staleTime: Infinity, // Cache forever, cache will be refreshed by certain operations
    retry: 2, // Only retry twice
    refetchOnWindowFocus: false, // Don't refresh when the user focuses the window
  });

  const [showUnlockDialog, setShowUnlockDialog] = useState();
  const [showCachedItemsDialog, setShowCachedItemsDialog] = useState();
  const [selectedEntity, setSelectedEntity] = useState({});
  const [cachedItems, setCachedItems] = useState([]);

  const isAdministrator = mode === 'administrator';

  const refreshEntities = () => {
    // Invalidate the 'locked-entities' query, this will cause react-query to re-read the
    // information which will in turn cause the table to be refreshed
    queryClient.invalidateQueries('locked-entities');
  };

  const unlockSelectedEntity = async (cacheMode = {}) => {
    try {
      await ElnSapi.unlockEntity(selectedEntity.entityId, cacheMode);
    } catch (e) {
      console.error('Failed to unlock entity', e);
    }
  };

  const onStartEntityUnlock = ({ entityId, entityName, lockedBy }) => {
    setShowUnlockDialog(true);
    setSelectedEntity({
      entityId,
      entityName,
      lockedBy,
    });
  };

  const onHideUnlockDialog = () => {
    setShowUnlockDialog(false);
  };

  const onHideCachedItemsDialog = () => {
    setShowCachedItemsDialog(false);
  };

  const onUnlockEntity = async (cacheMode) => {
    if (cacheMode) {
      // This branch runs once the list of cached items has been displayed
      setShowCachedItemsDialog(false);
      await unlockSelectedEntity(cacheMode);
      refreshEntities();
    } else {
      // This branch runs after the first 'unlock' dialog is displayed...
      // Make a call to check for cached entity items, if we have some, then display the 'cache'
      // unlock dialog otherwise unlock the entity, close the dialog and refresh
      try {
        const entityCachedItems = await ElnSapi.getCachedItems(selectedEntity.entityId);
        setShowUnlockDialog(false);
        setCachedItems(entityCachedItems);

        if (entityCachedItems.length > 0) {
          setShowCachedItemsDialog(true);
        } else {
          await unlockSelectedEntity();
          refreshEntities();
        }
      } catch (e) {
        console.error('Failed to check for cached entity items', e);
      }
    }
  };

  if (isError) {
    return <ErrorPage subHeading={intlMessage('app.error.entityRetrievalFailed')} />;
  }

  return (
    <>
      <Background />
      <Overlay>
        <Shell data-testid='application-shell' mode={mode}>
          <HeaderBar>
            {intlMessage('app.title')}
            <CrossIcon role='button' data-testid='close-application' onClick={closeApplication} />
          </HeaderBar>
          <ActionPanel>
            <ButtonPanel>
              <Button emphasis='secondary' testId='refresh-entities' onClick={refreshEntities}>
                {intlMessage('app.refreshEntities')}
              </Button>
            </ButtonPanel>
          </ActionPanel>
          <ApplicationContent>
            {isLoading ? (
              <LoadingIndicator />
            ) : (
              <EntitiesTable isAdministrator={isAdministrator} onStartEntityUnlock={onStartEntityUnlock} />
            )}
          </ApplicationContent>
          <EntityUnlockDialog
            isAdministrator={isAdministrator}
            show={showUnlockDialog}
            onHide={onHideUnlockDialog}
            selectedEntity={selectedEntity}
            onUnlockEntity={onUnlockEntity}
          />
          <CachedItemsDialog
            show={showCachedItemsDialog}
            onHide={onHideCachedItemsDialog}
            selectedEntity={selectedEntity}
            onUnlockEntity={onUnlockEntity}
            cachedItems={cachedItems}
          />
        </Shell>
      </Overlay>
    </>
  );
};

export { AppShell };
