import Communication from '../ewb-communication/communication';

/**
 * This file provides the code necessary to communication with the ELN SAPI
 */

// Constructs the headers that are required to make successful requests to the back end
const getHttpHeaders = (pasToken) =>
  new Headers({
    Accept: 'application/json',
    Authorization: `Bearer ${pasToken}`,
  });

let sapiUrl;
const initialise = (rootSapiUrl) => {
  sapiUrl = `${rootSapiUrl}services/1.0`;
};

/**
 * @returns promise that resolves with a list of locked entities
 */
const getEntityLocks = (mode) => {
  return new Promise((resolve, reject) => {
    Communication.getValidPasToken(async (pasToken) => {
      const retrievalType = mode === 'administrator' ? 'ALL' : 'USER';
      // Fetch entity locks using the pasToken
      try {
        const response = await fetch(`${sapiUrl}/locks/entities/find?retrievalType=${retrievalType}`, {
          headers: getHttpHeaders(pasToken),
        });

        if (response.ok) {
          const locksResponse = await response.json();
          resolve(locksResponse);
        } else {
          reject(response.statusText);
        }
      } catch (e) {
        reject(e);
      }
    });
  });
};

/**
 * @param {*} entityId
 * @returns a promise that resolves with a list of cached items for the associated entity
 */
const getCachedItems = (entityId) => {
  return new Promise((resolve, reject) => {
    Communication.getValidPasToken(async (pasToken) => {
      // Fetch entities using the pasToken
      try {
        const response = await fetch(`${sapiUrl}/locks/entities/${entityId}/islockNeeded`, {
          headers: getHttpHeaders(pasToken),
        });

        if (response.ok) {
          if (response.status === 204) {
            resolve([]);
          } else {
            const cachedItemsResponse = await response.json();
            const cachedItems = cachedItemsResponse.items.map(({ entityTypeOccurences, entityTypeName }) => {
              return `${entityTypeName} (${entityTypeOccurences})`;
            });
            resolve(cachedItems);
          }
        } else {
          reject(response.statusText);
        }
      } catch (e) {
        reject(e);
      }
    });
  });
};

/**
 * Unlock the specified entity
 * @param {*} entityId
 * @returns a promise that resolves once the entity has been unlocked
 */
const unlockEntity = (entityId, cacheMode = {}) => {
  const { retainCache, clearCache } = cacheMode;
  const baseUrl = `${sapiUrl}/locks/entities/${entityId}/lock`;
  const url = baseUrl + (retainCache ? '?cachePreserve=true' : '') + (clearCache ? '?cacheOverride=true' : '');

  return new Promise((resolve, reject) => {
    Communication.getValidPasToken(async (pasToken) => {
      // Unlock the entity using the pasToken
      try {
        const response = await fetch(url, {
          headers: getHttpHeaders(pasToken),
          method: 'DELETE',
        });

        if (response.ok) {
          resolve();
        } else {
          reject(response.statusText);
        }
      } catch (e) {
        reject(e);
      }
    });
  });
};

export default { initialise, getEntityLocks, getCachedItems, unlockEntity };
