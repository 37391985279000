import { useIntl } from 'react-intl';

const useIntlMessage = () => {
  const intl = useIntl();
  return (id, values, defaultMessage) => intl.formatMessage({ id, defaultMessage }, values);
};

const clientLocale = navigator.language || 'en_GB';
const dateFormatter = new Intl.DateTimeFormat(clientLocale, { day: 'numeric', month: 'short', year: 'numeric' });

export { useIntlMessage, dateFormatter };
