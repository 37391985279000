import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useQueryClient } from 'react-query';
import Table from '@idbs/idbs-react-advanced-table';
import { FormattedTime } from 'react-intl';
import { Button } from '@idbs/idbs-react-components';
import { LockOpenIcon } from '@idbs/idbs-react-icon';
import { useIntlMessage, dateFormatter } from '../utils/useIntlMessage';

const createUnlockCellRenderer = (onSelectEntity) => {
  return (options) => {
    const { row } = options;
    const intlMessage = useIntlMessage();
    return (
      <Button
        icon={<LockOpenIcon />}
        emphasis='ghost'
        iconText={intlMessage('app.unlockEntity')}
        testId='unlock-selected-entity'
        data-entityid={row.original.entityId}
        onClick={() =>
          onSelectEntity({
            entityId: row.original.entityId,
            entityName: row.original.entityName,
            lockedBy: row.original.userFullName,
          })
        }
      />
    );
  };
};

const LoggedInCell = ({ row }) => {
  const intlMessage = useIntlMessage();
  return <>{intlMessage(row.values.userLoggedIn ? 'app.yes' : 'app.no')}</>;
};

const NoOverflowCell = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const createDateCellRender = (dateField) => {
  return ({ row }) => {
    if (!row.values[dateField]) {
      return null;
    }
    return (
      <NoOverflowCell>
        {dateFormatter.format(row.values[dateField])}
        &nbsp;
        <FormattedTime value={row.values[dateField]} />
      </NoOverflowCell>
    );
  };
};

const createTableColumns = (intlMessage, onSelectEntity, isAdministrator) => {
  const tableColumns = [
    {
      Cell: createUnlockCellRenderer(onSelectEntity),
      Header: '',
      id: 'Unlock',
      isSortable: false,
      width: 50,
      maxWidth: 50,
      minWidth: 50,
    },
    {
      Header: intlMessage('app.entityName'),
      accessor: 'entityName',
      isSortable: true,
      width: isAdministrator ? 150 : 250,
    },
    {
      Header: intlMessage('app.entityPath'),
      accessor: 'entityPath',
      isSortable: true,
      width: isAdministrator ? 150 : 350,
    },
    {
      Cell: createDateCellRender('entityLockedAt'),
      Header: intlMessage('app.lockedTime'),
      accessor: 'entityLockedAt',
      isSortable: true,
      width: 150,
    },
  ];

  if (isAdministrator) {
    tableColumns.push(
      {
        Header: intlMessage('app.lockedBy'),
        accessor: 'entityLockedByUser',
        isSortable: true,
        width: 100,
      },
      {
        Header: intlMessage('app.fullName'),
        accessor: 'userFullName',
        isSortable: true,
        width: 150,
      },
      {
        Header: intlMessage('app.email'),
        accessor: 'userEmail',
        isSortable: true,
        width: 150,
      },
      {
        Cell: createDateCellRender('userLastLoggedIn'),
        Header: intlMessage('app.lastLogin'),
        accessor: 'userLastLoggedIn',
        isSortable: true,
        width: 150,
      },
      {
        Cell: LoggedInCell,
        Header: intlMessage('app.loggedIn'),
        accessor: 'userLoggedIn',
        isSortable: true,
        sortType: 'basic',
        width: 100,
      },
    );
  }

  return tableColumns;
};

const EntitiesTable = ({ isAdministrator, onStartEntityUnlock }) => {
  const queryClient = useQueryClient();
  const intlMessage = useIntlMessage();

  const onSelectEntity = useCallback(
    ({ entityId, entityName, lockedBy }) => {
      onStartEntityUnlock({
        entityId,
        entityName,
        lockedBy,
      });
    },
    [onStartEntityUnlock],
  );

  const tableColumns = useMemo(() => {
    return createTableColumns(intlMessage, onSelectEntity, isAdministrator);
    // Ignore warnings about missing dependencies as we only want to create the table columns once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const lockedEntitiesResponse = queryClient.getQueryData('locked-entities');
  const lockedEntities = lockedEntitiesResponse ? lockedEntitiesResponse.lockInfo : lockedEntitiesResponse;

  return <Table testId='entities-table' columns={tableColumns} summaryData={lockedEntities} paginationEnabled />;
};

export default EntitiesTable;
